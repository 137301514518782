
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































































































































.overline {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: $c-main;
  transform-origin: 0 0;

  @media (prefers-reduced-motion) {
    display: none;
  }
}

.hero {
  position: relative;
  z-index: 1;
  transform-origin: 0 0;
  height: 100vh;

  @media (prefers-reduced-motion) {
    height: 90vh;
  }
}

.hero-counter-scale {
  transform-origin: bottom center;
  width: 100%;
  height: 100%;
}

.hero__visual-container {
  transform-origin: 0 0;
  height: 100vh;
}

.hero__content {
  display: flex;
  width: 100%;

  > .row {
    width: 100%;
    margin: auto 0 0;
  }
}

.hero__logo {
  @include liquid(width, 280px, 892px);
  @include liquid(margin-bottom, 40px, 130px);

  position: relative;
  height: auto;
  max-width: 100%;
  transform-origin: 0 100%;
}

[class*='hero__logo__path'] {
  // Initial state is black, paths' fill will be animated to its final color using JS
  // see playOnboardingAnimation
  fill: $c-black;

  // If no motion, set default paths color from CSS
  @media (prefers-reduced-motion) {
    &[class*='--flame'] {
      fill: $c-main;
    }

    &[class*='--text'] {
      fill: $c-white;
    }
  }
}

.expertise {
  &__visual {
    width: 100%;
  }

  .bleed {
    background-color: $c-main;
  }
}

.expertise-section::v-deep {
  position: relative;
  z-index: 1; // Create new stacking context, allow to use -1 on background
  color: $c-text-light;
  background: $c-surface-dark;

  .expertise-section__background-container {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);

    @include mq($until: wrapper) {
      overflow: hidden;
    }
  }

  .link:hover,
  .link:focus {
    .link__svg {
      fill: currentColor;
    }
  }
}

::v-deep {
  .header {
    .logo {
      display: none;
    }

    &.sticky,
    &.hidden {
      .logo {
        display: block;
      }
    }
  }

  .solutions-section {
    padding-bottom: 0;

    .intro {
      @include mq($until: m) {
        margin-bottom: 4rem;
      }
    }
  }

  .solution-title {
    background-color: $c-surface-light;
  }

  img.hero__visual {
    // Will be animated during onboarding animation using JS
    opacity: 1;

    // If no motion, set final opacity
    @media (prefers-reduced-motion) {
      opacity: 0.6;
    }
  }
}

.logo-anniversary {
  width: 100%;
  max-width: 20.6rem;
}
